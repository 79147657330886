document.addEventListener('DOMContentLoaded', () => {
  // show more HGM content
  const showMoreBtn = document.getElementById('showMore_HGM');
  if (!showMoreBtn) return;

  showMoreBtn.addEventListener('click', () => {
    document.querySelector('.sub_description .wrap_text').style.display =
      'block';
  });

  const societiesTitle = document.querySelector(
    '.societies_container .wrap_text'
  );
  if (!societiesTitle) return;

  const divHeight = societiesTitle.offsetHeight;
  const lineHeight = parseFloat(getComputedStyle(societiesTitle).lineHeight);
  const numberOfLines = Math.round(divHeight / lineHeight) - 1;

  if (numberOfLines > 11) {
    showMoreBtn.classList.add('visible');
    societiesTitle.classList.add('limit');
  } else {
    societiesTitle.classList.add('visible');
  }
});
