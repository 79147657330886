/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');

// IE11対策
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// images
require.context('../images/front', true);

// fontawesome
import '@fortawesome/fontawesome-free/css/all';

// css
import '../styles/front/style';

// js
import 'whatwg-fetch';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import '../scripts/front/adjust_heights';
import '../scripts/front/certification_check_modal';
import '../scripts/front/update_user_information_modal';
import '../scripts/front/click_log';
import '../scripts/front/user_form';
import '../scripts/front/content';
import '../scripts/front/remove_tie_modal_switcher';
import '../scripts/front/vimeo';
import '../scripts/front/scroll';
import '../scripts/front/scroll_series_title';
import '../scripts/front/production_bbs_form_modal';
import '../scripts/front/societies';
import './thumb_loader';

// swipers
import 'swiper/swiper-bundle.min.css';
// home banners
import '../scripts/front/home_banner_swiper';
// 医療機器: 機器画像スライド
import '../scripts/front/device-swiper';
// Home: かわらばん 各vol.スライド
import '../scripts/front/kawaraban_swiper';
// mobile表示スライド: [医薬品/医療機器] 関連動画, 関連資料 | [コンテンツ] 関連医薬品, 関連医療機器
import '../scripts/front/product_swiper';
// card カルーセル
import '../scripts/front/home_card_swiper';
